import { useState, useEffect, RefObject } from 'react';

/**
 * Custom hook to calculate the minimum content height of an element.
 *
 * @param ref - React ref object pointing to the target HTML element.
 * @returns The minimum content height in pixels, or undefined if not yet calculated.
 */
function useMinContentHeight<T extends HTMLElement>(
  ref: RefObject<T>,
  disabled: boolean = false,
): number | undefined {
  const [minHeight, setMinHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (disabled) return;

    const element = ref.current;
    if (!element) return;

    let resizeObserver: ResizeObserver | null = null;
    let mutationObserver: MutationObserver | null = null;
    let clone: HTMLElement | null = null;

    /**
     * Measures the minimum content height by cloning the element,
     * applying necessary styles, and measuring its height.
     */
    const measureMinHeight = () => {
      if (!element) return;

      // Clone the target element deeply (including its children)
      clone = element.cloneNode(true) as HTMLElement;

      // Apply styles to ensure the clone does not interfere with the layout
      Object.assign(clone.style, {
        position: 'absolute',
        visibility: 'hidden',
        height: 'auto', // Let the height adjust to content
        minHeight: 'min-content',
        maxHeight: 'none', // Remove any max-height constraints
        overflow: 'visible', // Ensure all content is visible
        width: `${element.offsetWidth}px`, // Match the width of the original element
        animaton: 'unset',
      });

      // Append the clone to the body to render it
      document.body.appendChild(clone);

      // Measure the height of the clone
      const measuredHeight = Math.round(clone.getBoundingClientRect().height);

      // Clean up by removing the clone from the DOM
      document.body.removeChild(clone);
      clone = null;

      // Update the state with the measured minimum height
      setMinHeight(measuredHeight);
    };

    // Initial measurement
    measureMinHeight();

    // Set up a ResizeObserver to watch for changes in the target element
    resizeObserver = new ResizeObserver(measureMinHeight);
    mutationObserver = new MutationObserver(measureMinHeight);

    resizeObserver.observe(element);
    mutationObserver.observe(element, { attributes: true, childList: true, subtree: true });

    // Clean up on component unmount
    return () => {
      resizeObserver?.unobserve(element);
      resizeObserver?.disconnect();
      mutationObserver.disconnect();

      if (clone && clone.parentElement) {
        clone.parentElement.removeChild(clone);
      }
    };
  }, [ref, disabled]);

  return minHeight;
}

export default useMinContentHeight;
