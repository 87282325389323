import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import {
  QueryResourceListQueryOptions,
  useQueryResourceList,
} from '@/src/modules/resources/queries/useQueryResourceList';
import { ResourceDetailFolder } from '@/src/modules/resources/resources.types';

/**
 * fetches all folders within a parent folder
 *
 * @param queryOptions
 * @returns
 */
export const useQueryFolders = (
  parentId?: string,
  queryOptions?: QueryResourceListQueryOptions,
) => {
  const { resources, ...query } = useQueryResourceList(
    {
      parentId,
      /**
       * @TODO
       * no consumer handles lazy fetching, so we need to set high limit
       * https://linear.app/futurebrowser/issue/FUT-4935/usequeryfolders-now-fetching-up-to-200-items-need-to-check-consumers
       */
      limit: 200,
      kind: ['folder'],
    },
    {
      ...queryOptions,
      enabled: isQueryEnabled([queryOptions?.enabled, !!parentId]),
    },
  );

  return {
    ...query,
    folders: resources as ResourceDetailFolder[],
  };
};
