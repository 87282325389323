import { isTruthy } from '@/src/utils/guards';

interface TransformConfig {
  rotate?: number;
  translate?: [number, number];
  scale?: number;
}

interface GroupConfiguration {
  default: TransformConfig[];
  hovered: TransformConfig[];
}

const threeItemConfig: GroupConfiguration = {
  default: [
    {
      rotate: 7.31,
      translate: [-50, -90],
      scale: 0.8,
    },
    {
      rotate: -2.64,
      translate: [50, 0],
      scale: 0.8,
    },
    {
      rotate: -5.93,
      translate: [-50, 90],
      scale: 0.8,
    },
  ],
  hovered: [
    {
      rotate: 7.31,
      translate: [-60, -85],
      scale: 0.9,
    },
    {
      rotate: -2.64,
      translate: [75, 0],
      scale: 0.9,
    },
    {
      rotate: -5.93,
      translate: [-60, 90],
      scale: 0.9,
    },
  ],
};

const twoItemConfig: GroupConfiguration = {
  default: [
    {
      rotate: -3.85,
      translate: [-50, -60],
      scale: 0.86,
    },
    {
      rotate: 7.82,
      translate: [50, 60],
      scale: 0.86,
    },
  ],
  hovered: [
    {
      rotate: -3.85,
      translate: [-65, -65],
      scale: 0.96,
    },
    {
      rotate: 7.82,
      translate: [65, 65],
      scale: 0.96,
    },
  ],
};

const oneItemConfig: GroupConfiguration = {
  default: [{}],
  hovered: [
    {
      scale: 1.1,
    },
  ],
};

const animationConfig = {
  1: oneItemConfig,
  2: twoItemConfig,
  3: threeItemConfig,
};

const buildConfigTransformStyle = (
  transformConfig: TransformConfig,
  options: {
    horizontalSpread?: number;
    verticalSpread?: number;
    zDepth: number;
  },
) => {
  const horizontalSpread = options.horizontalSpread || 1;
  const verticalSpread = options.verticalSpread || 1;

  return [
    transformConfig.rotate && `rotate(${transformConfig.rotate}deg)`,
    transformConfig.translate &&
      `translate3d(${transformConfig.translate[0] * horizontalSpread}px, ${transformConfig.translate[1] * verticalSpread}px, ${options.zDepth}px)`,
    transformConfig.scale && `scale(${transformConfig.scale})`,
  ]
    .filter(isTruthy)
    .join(' ');
};

const buildStyles = (
  config: GroupConfiguration,
  options: {
    transitionState: 'hovered' | 'default';
    horizontalSpread?: number;
    verticalSpread?: number;
    zDepth: number;
  },
) => {
  return config[options.transitionState].map((transformConfig, index) => {
    return {
      transform: buildConfigTransformStyle(transformConfig, {
        horizontalSpread: options.horizontalSpread,
        verticalSpread: options.verticalSpread,
        zDepth: index * options.zDepth,
      }),
    };
  });
};

export const getStyles = (options: {
  listSize: number;
  transitionState: 'hovered' | 'default';
  index: number;
  noTransition?: boolean;
  horizontalSpread?: number;
  verticalSpread?: number;
  addDepth?: boolean;
}) => {
  const {
    listSize,
    transitionState,
    index,
    noTransition,
    horizontalSpread,
    verticalSpread,
    addDepth,
  } = options;

  const size = Math.max(Math.min(listSize, 3), 1) as 1 | 2 | 3;
  const config = animationConfig[size];

  if (config) {
    const stylesConfig = buildStyles(config, {
      transitionState,
      horizontalSpread,
      verticalSpread,
      zDepth: addDepth ? 40 : 0,
    });

    return {
      ...stylesConfig[index],
      transition: noTransition ? 'none' : undefined,
    };
  }

  return undefined;
};
